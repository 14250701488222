import React from 'react';
import { ReactComponent as Patience } from '../assets/patience.svg';
import { RaceBy } from '@uiball/loaders';

function Loader({ text }) {
	return (
		<div className="loading-container">
			<Patience height="300px" />
			<RaceBy size={80} lineWeight={5} speed={1.4} color="white" />;
			<p className="loading-text">"{text}"</p>
		</div>
	);
}

export default Loader;
