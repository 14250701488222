import React from 'react';

function ProjectItem({ project }) {
	return (
		<>
			<div className="project-card">
				<div className="info-card">
					<div className="project-title">{project.title}</div>
					<div className="project-description">
						<div className="project-title">{project.title}</div>
						<span>{project.description}</span>

						<div className="stack-box">
							{project.stack}
							{/* {project.stack.map((stack, index) => (
												<span key={index}>{stack.trim()}</span>
											))} */}
						</div>

						<a
							href={project.link}
							target="_blank"
							rel="noreferrer"
							className="project-button">
							VIEW PROJECT
						</a>
					</div>
				</div>
				<div className="image-card">
					<img src={project.image} alt={project.title} width="600px" />
				</div>
			</div>
		</>
	);
}

export default ProjectItem;
