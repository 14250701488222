import { useState } from 'react';
import ContactImage from '../assets/contact-fola.svg';
import axios from 'axios';
import Loading from './loading';
import RavenSuccess from './ravenSuccess';

function Raven({ Theme }) {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [error, setError] = useState(null);
	const [emailSuccess, setEmailSuccess] = useState(false);
	const [loading, setLoading] = useState(false);
	const [characterCount, setCharacterCount] = useState(0);
	const [isValid, setIsValid] = useState(false);
	const [isEmailValid, setIEmailsValid] = useState(false);
	const [disabled, setDisbaled] = useState(false);

	const validateEmail = (e) => {
		if (new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(e.trim())) {
			setIEmailsValid(true);
		} else {
			setIEmailsValid(false);
		}
	};

	const characterCounter = (e) => {
		setCharacterCount(e.length);
		if (characterCount < 2) {
			setIsValid(false);
		} else {
			setIsValid(true);
		}
	};

	const handleSubmit = async () => {
		setLoading(true);
		const body = { email, message, name };
		const response = await axios.post(
			'https://serene-thicket-77721.herokuapp.com/api/sendMail',
			body,
		);

		if (response.data.message === 'Message sent!') {
			setLoading(false);
			setEmailSuccess(true);
		} else {
			setLoading(false);
			setError('Looks like something broke. I promise I will fix it 🛠');
		}
	};

	return (
		<div className="container">
			<div className="content-wrapper">
				<div className="image-box">
					<img src={ContactImage} alt="folarin" />
				</div>
				<div className="form-box">
					<h1 className="form-title">Reach Out to Me</h1>

					{emailSuccess ? (
						<RavenSuccess />
					) : (
						<>
							<div className="form-pills">
								<div className="pill">Say Hello</div>
								<div className="pill">Chat about work</div>
								<div className="pill">Music Recommendations</div>
							</div>

							<div className="form-container">
								<div className="form-group">
									<label htmlFor="Name">Name</label>
									<input
										type="text"
										name="Name"
										className="form-input"
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</div>

								<div className="form-group">
									<label htmlFor="eMail">eMail</label>
									<input
										type="email"
										name="eMail"
										autoComplete="off"
										className="form-input"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										onKeyDown={(e) => validateEmail(e.target.value)}
									/>
									{/* {eMail.length > 15 && !isEmailValid && (
									<span className="text-negative">
										hmm! that doesn't look right 👀
									</span>
								)} */}
								</div>

								<div className="form-group">
									<label htmlFor="eMail">Message</label>
									<textarea
										rows="10"
										className="form-text"
										value={message}
										onChange={(e) => setMessage(e.target.value)}
										onKeyUp={(e) => characterCounter(e.target.value)}
									/>
								</div>
							</div>

							<button
								className={`form-button ${
									isValid &&
									isEmailValid &&
									name.trim().length &&
									email.trim().length &&
									message.trim().length
										? ''
										: 'disabled'
								}`}
								onClick={() => handleSubmit()}>
								{loading ? <Loading Theme={Theme} /> : 'SEND e-RAVEN'}
							</button>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default Raven;
