import React from 'react';

const Loading = ({ Theme }) => {
	return (
		<div
			className="loading"
			style={{ borderTopColor: Theme === 'dark' ? '#e5e5e5' : '#0a0908' }}></div>
	);
};

export default Loading;
