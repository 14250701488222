import React, { useEffect, useRef, useState } from 'react';
import { gsap, Power3, Power4 } from 'gsap';
import axios from 'axios';
import { setTheme } from '../utils/themes';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from '../assets/logo-fola.svg';
import { ReactComponent as Charge } from '../assets/charge.svg';
import { ReactComponent as Hammer } from '../assets/hammer.svg';
import { ReactComponent as Twitter } from '../assets/twitter.svg';
import { ReactComponent as Linkedin } from '../assets/linkedin.svg';
import { ReactComponent as Instagram } from '../assets/instagram.svg';
import { ReactComponent as Github } from '../assets/github.svg';
import { ReactComponent as Love } from '../assets/heart.svg';
import { ReactComponent as Light } from '../assets/light.svg';

import DayMode from '../assets/day.svg';
import NightMode from '../assets/night.svg';
import Hello from '../assets/hello-fola.svg';
import About from '../assets/about-fola.svg';
import Raven from '../components/eRaven';
import ProjectItem from '../components/projectItem';
import LabItem from '../components/labItem';
import Loader from '../components/loader';

// https://serene-thicket-77721.herokuapp.com

export default function Home() {
  let nameTag = useRef(null);
  let intro = useRef(null);
  let introText = useRef(null);
  let nav = useRef(null);

  const [togClass, setTogClass] = useState('dark');

  let theme = localStorage.getItem('theme');

  const [projects, setProjects] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const currentDate = new Date();

  const fetchProjects = async () => {
    setLoading(true);
    const response = await axios.get('https://portfolio-apis.fly.dev/api/projects/public');
    if (response.data) {
      const { data } = response;
      setProjects(data);
      setLoading(false);
    }
  };

  const handleOnClick = () => {
    if (localStorage.getItem('theme') === 'theme-dark') {
      setTheme('theme-light');
      setTogClass('light');
    } else {
      setTheme('theme-dark');
      setTogClass('dark');
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('theme') === 'theme-dark') {
      setTogClass('dark');
    } else if (localStorage.getItem('theme') === 'theme-light') {
      setTogClass('light');
    }
  }, [theme]);

  useEffect(() => {
    var tl = gsap.timeline();

    tl.from(intro, { opacity: 0, y: 35, ease: Power3.easeOut, stagger: 0.3, delay: 0.28 }, 0.1)
      .from(nameTag, { opacity: 0, y: -25, ease: Power4.easeOut, stagger: 0.3, delay: 0.32 }, 0.4)
      .from(
        introText,
        1.8,
        {
          opacity: 0,
          y: 25,
          ease: Power4.easeOut,
          // skewY: 10,
          stagger: 0.4,
          delay: 0.36
        },
        0.5
      )
      .from(nav, { y: -10, opacity: 0, ease: Power3.easeOut, delay: 0.42 }, 0.55);
  }, []);
  return (
    <div className="main">
      <section>
        <div
          className="nav-wrapper"
          ref={(el) => {
            nav = el;
          }}>
          <div className="logo-box">
            <Logo
              stroke="currentColor"
              style={{
                color: togClass === 'dark' ? '#e5e5e5' : '#0a0908',
                width: '75%'
              }}
              className="brand"
            />
          </div>
          <div className="nav-actions">
            <Link to="/Folarin_Farinto.pdf" target="_blank" className="nav-button" download>
              <div
                className="button-lg"
                style={{
                  backgroundColor: theme === 'theme-dark' ? 'transparent' : '#1d1d1d'
                }}>
                <span
                  className="btn-text"
                  style={{
                    color: theme === 'theme-dark' ? '#e5e5e5' : '#fff'
                  }}>
                  RESUME
                </span>
              </div>
            </Link>

            <div
              className="button-lg btn-narrow"
              style={{
                backgroundColor: theme === 'theme-dark' ? 'transparent' : '#1d1d1d'
              }}
              onClick={() => handleOnClick()}>
              {togClass === 'dark' ? (
                <img className="nav-icon" src={DayMode} alt="dark mode" />
              ) : (
                <img className="nav-icon" src={NightMode} alt="light mode" />
              )}
            </div>
          </div>
        </div>

        <div className="hero-wrapper">
          <div className="hero-container">
            <div
              className="bold-text"
              ref={(el) => {
                intro = el;
              }}>
              <h1>HELLO</h1>
            </div>

            <div className="info-wrap">
              <div className="image-box">
                <img
                  src={Hello}
                  alt="Folarin"
                  ref={(el) => {
                    nameTag = el;
                  }}
                />
              </div>

              <div
                className="hero-paragraph"
                ref={(el) => {
                  introText = el;
                }}>
                <p>
                  My name is Folarin, a software engineer based in Toronto, Canada with a primary
                  focus on modern frontend technologies and a passion for writing functional and
                  clean code.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-section">
        <div className="container">
          <h1>About Me</h1>

          <div className="content-container">
            <div className="text-box">
              <p>
                I am a software engineer with over 4 years experience in designing and developing
                software in both backend and front-end roles.
              </p>

              <p>
                I enjoy solving problems and building things that live on the web. I challenge
                myself with building beautiful, functional and performant web and mobile
                applications with different experiences.
              </p>

              <p>
                I am currently working as front-end engineer, learning about the applications of
                blockchain technology and exploring web3 applications in the DeFi, NFT and DAO
                space.
              </p>

              <p>
                I graduated from Middlesex University (MSc) and I have worked with some incredible
                organisations including{' '}
                <a href="https://timwetech.com/" target="_blank" rel="noreferrer" className="timwe">
                  Timwe
                </a>
                ,{' '}
                <a
                  href="https://www.ubagroup.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="uba">
                  United Bank for Africa
                </a>
                ,{' '}
                <a
                  href="https://providusbank.com/"
                  className="providus"
                  target="_blank"
                  rel="noreferrer">
                  ProvidusBank
                </a>
                ,{' '}
                <a href="https://kuda.com/" className="kuda" target="_blank" rel="noreferrer">
                  Kuda MFB
                </a>{' '}
                and{' '}
                <a
                  href="https://www.reasononeinc.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="reason-one">
                  Reason One
                </a>
                .
              </p>

              <p>Here are a few languages and frameworks I’ve been working with recently:</p>
              <ul className="skill-list">
                <li>Javascript </li>
                <li>TypeScript </li>
                <li>React JS</li>
                <li>Next JS</li>
                <li>React Native</li>
                <li>Node JS </li>
                <li>Solidity</li>
              </ul>
            </div>

            <div className="image-box">
              <img src={About} alt="Folarin Farinto" />
            </div>
          </div>
        </div>
      </section>

      <>
        {loading ? (
          <section className="project-section">
            <Loader text="Patience is not the ability to wait, but the ability to keep a good attitude while waiting." />
          </section>
        ) : (
          <>
            <section className="project-section">
              <div className="container">
                <div className="title-box">
                  <Hammer
                    style={{
                      color: togClass === 'dark' ? '#e5e5e5' : '#0a0908',
                      width: '45%'
                    }}
                  />
                  <h1>Things I’ve Built</h1>
                  <span>Projects I have worked on and want to show off 😉</span>
                </div>

                <div className="project-wrapper">
                  {projects
                    .filter((item) => item.isLab === false)
                    .map((project, index) => (
                      <ProjectItem project={project} key={index} />
                    ))}
                </div>
              </div>
            </section>

            <section className="science-lab">
              <div className="container">
                <div className="title-box">
                  <Charge style={{ color: togClass === 'dark' ? '#e5e5e5' : '#0a0908' }} />
                  <h1>Science Lab</h1>
                  <span>Some things I’m exploring and experimenting with</span>
                </div>

                <div className="lab-wrapper">
                  {projects
                    .filter((item) => item.isLab === true)
                    .map((project, index) => (
                      <LabItem project={project} togClass={togClass} Github={Github} key={index} />
                    ))}
                </div>

                <div className="button-box">
                  <a
                    href="https://github.com/bushcode?tab=repositories"
                    target="_blank"
                    rel="noreferrer"
                    className="button">
                    VIEW ARCHIVE
                  </a>
                </div>
              </div>
            </section>
          </>
        )}
      </>

      <section className="contact-section">
        <Raven Theme={togClass} />
      </section>

      <div className="footer-wrapper">
        <div className="footer-inner">
          <div className="social-box">
            <span>Connect with me on Social Media</span>
            <div className="icon-box">
              <a href="https://twitter.com/folarinfarinto" rel="noreferrer" target="_blank">
                <Twitter
                  className="social-icon"
                  style={{ color: togClass === 'dark' ? '#e5e5e5' : '#0a0908' }}
                />
              </a>

              <a href="https://github.com/bushcode" rel="noreferrer" target="_blank">
                <Github
                  className="social-icon"
                  style={{ color: togClass === 'dark' ? '#e5e5e5' : '#0a0908' }}
                />
              </a>
              <a href="https://www.instagram.com/_folar.in/?hl=en" rel="noreferrer" target="_blank">
                <Instagram
                  className="social-icon"
                  style={{ color: togClass === 'dark' ? '#e5e5e5' : '#0a0908' }}
                />
              </a>
              <a
                href="https://www.linkedin.com/in/folarin-farinto/"
                rel="noreferrer"
                target="_blank">
                <Linkedin
                  className="social-icon"
                  style={{ color: togClass === 'dark' ? '#e5e5e5' : '#0a0908' }}
                />
              </a>
            </div>
          </div>
          <div className="message-box">Designed and Built by Folarin Farinto</div>
          <div className="message-box">
            <Love className="icon" style={{ color: 'red' }} />
            <Light className="icon" style={{ color: 'yellow' }} /> -{currentDate.getFullYear()}
          </div>
        </div>
      </div>
    </div>
  );
}
