import React from 'react';

const RavenSuccess = () => {
  return (
    <div className="success-container">
      <div className="success-checkmark">
        <div className="check-icon">
          <span className="icon-line line-tip"></span>
          <span className="icon-line line-long"></span>
          <div className="icon-circle"></div>
          <div className="icon-fix"></div>
        </div>
      </div>
      <p className="raven-header">Great Success!</p>
      <p className="raven-text">
        Thank you for reaching, your raven has been sent 🦅. I will be in touch soon.
      </p>
    </div>
  );
};

export default RavenSuccess;
