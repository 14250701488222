import './styles/css/app.css';
import './styles/css/modern-normalize.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { gsap } from 'gsap';
import { keepTheme } from './utils/themes';

import Home from './pages';
import { useEffect, useRef } from 'react';

function App() {
  let app = useRef(null);

  useEffect(() => {
    keepTheme();
  });

  useEffect(() => {
    gsap.to(app, { css: { visibility: 'visible' } });
  }, []);
  return (
    <div className="App" ref={(el) => (app = el)}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
