import React from 'react';
import { ReactComponent as LinkIcon } from '../assets/link.svg';

function LabItem({ project, togClass, Github }) {
	return (
		<>
			<div className="lab-item">
				<div className="lab-item--header">
					<span className="lab-item--title">{project.title}</span>
					<span className="lab-item--description">{project.description}</span>
				</div>

				<div className="lab-links">
					<a href={project.repository} target="_blank" rel="noreferrer">
						<Github
							style={{
								color: togClass === 'dark' ? '#e5e5e5' : '#0a0908',
								height: '32px',
								width: '32px',
							}}
						/>
					</a>

					<a href={project.link} target="_blank" rel="noreferrer">
						<LinkIcon
							className="social-icon"
							style={{
								color: togClass === 'dark' ? '#e5e5e5' : '#0a0908',
								height: '32px',
								width: '32px',
							}}
						/>
					</a>
				</div>
			</div>
		</>
	);
}

export default LabItem;
